<template>
  <div class="container my-24 my-lg-40">
    <div class="row mb-8">
      <header class="col">
        <b>Страница 1 из 15</b>
      </header>
    </div>

    <div class="row">
      <div class="col-12">
        <v-card class="py-32 px-16 p-xl-32">
          <div class="row">
            <div class="col">
              <p>
                Внимательно прочитайте все высказывания и оцените степень своего согласия с каждым из них.
              </p>
            </div>
          </div>

          <div class="row d-none d-md-block">
            <div class="col">
              <v-table
                striped
                sticky
              >
                <template #thead>
                  <v-table-tr>
                    <v-table-th class="text-left">
                      <slot name="th-first">
                        Утверждения
                      </slot>
                    </v-table-th>

                    <v-table-th
                      v-for="tableTitle in table.cols"
                      :key="tableTitle.code"
                    >
                      {{ tableTitle.text }}
                    </v-table-th>
                  </v-table-tr>
                </template>

                <template #default>
                  <v-table-tr
                    v-for="(row, answerRow) in table.rows"
                    :key="row.code"
                    :active="answers[answerRow].every(answerCol => !!answerCol.value)"
                  >
                    <v-table-td>
                      {{ row.text }}
                    </v-table-td>

                    <v-table-td
                      v-for="(col, answerCol) in table.cols"
                      :key="col.code"
                      class="text-center"
                    >
                      <div class="d-inline-flex align-items-center">
                        <div class="pr-16">
                          <star-line-drop
                            v-model="answers[answerRow][answerCol].value"
                            :variant="answers[answerRow][answerCol].variant"
                          />
                        </div>
                        <div class="text-nowrap">
                          {{ answers[answerRow][answerCol].value || 0 }} /
                          {{ answers[answerRow][answerCol].variant.length }}
                        </div>
                      </div>
                    </v-table-td>
                  </v-table-tr>
                </template>
              </v-table>
            </div>
          </div>

          <div class="row d-md-none text-size-14">
            <div class="col">
              <div
                class="row mb-16"
                v-for="(row, answerRow) in table.rows"
                :key="row.code"
              >
                <div class="col">
                  <v-card-button
                    :active="answers[answerRow].every(answerCol => !!answerCol.value)"
                    :ripple="false"
                    bgSolid
                  >
                    <div class="row mb-16">
                      <div class="col">
                        {{ row.text }}
                      </div>
                    </div>

                    <div class="row mx-n4 mb-n4">
                      <div
                        class="col-12 p-4"
                        v-for="(col, answerCol) in table.cols"
                        :key="col.code"
                      >
                        <v-card-button
                          :active="!!answers[answerRow][answerCol].value"
                          :ripple="false"
                          bgSolid
                        >
                          <div class="row align-items-center">
                            <div class="col">
                              {{ col.text }}
                              <div class="text-nowrap text-size-12 text-bold">
                                {{ answers[answerRow][answerCol].value || 0 }} /
                                {{ answers[answerRow][answerCol].variant.length }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <star-line-replace
                                v-model="answers[answerRow][answerCol].value"
                                :variant="answers[answerRow][answerCol].variant"
                              />
                            </div>
                          </div>
                        </v-card-button>
                      </div>
                    </div>
                  </v-card-button>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-40">
            <div class="col">
              <v-button @click="onClick">
                Ответить
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { createCustomArray } from '@utils/helpers'
import { Resize } from '@directives'
import {
  VButton,
  VCard,
  VCardButton,
  VTable,
  VTableTd,
  VTableTh,
  VTableTr
} from '@components/base'
import {
  StarLineDrop,
  StarLineReplace
} from '@components/features'

const tableHeadTitles = [
  'Качество',
  'Скорость',
  'Клиентоориентированность'
]

export default {
  name: 'S360StarsInTable',

  directives: {
    Resize
  },

  components: {
    StarLineDrop,
    StarLineReplace,
    VButton,
    VCard,
    VCardButton,
    VTable,
    VTableTd,
    VTableTh,
    VTableTr
  },

  data () {
    return {
      answers: createCustomArray({
        template: i => (createCustomArray({
          length: 3,
          template: j => ({
            value: null,
            variant: createCustomArray({ template: i => ({ id: `R${i}C${j}` }) })
          })
        }))
      }),
      starLineMode: 'line',
      table: {
        rows: createCustomArray({ template: i => ({ id: `R${i}`, text: `Подразделение ${i + 1}` }) }),
        cols: createCustomArray({ length: 3, template: i => ({ id: `C${i}`, text: tableHeadTitles[i] }) })
      }

    }
  },

  methods: {
    onClick () {
      console.log('clicked')
    }
  }
}
</script>
